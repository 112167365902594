import React, { useEffect } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Linkedin from "./../styles/images/linkedin.inline.svg";
import Facebook from "./../styles/images/facebook.inline.svg";
import Twiter from "./../styles/images/twiter.inline.svg";

const ShareButtons = ({ url, title, description }) => (
  <>
    <FacebookShareButton url={url} quote={description}>
      <Facebook />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={description}>
      <Twiter />
    </TwitterShareButton>
    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin />
    </LinkedinShareButton>
  </>
);
export default ShareButtons;
