import React from "react";
import { graphql, Link } from "gatsby";
import { differenceInDays, formatDistance, format } from "date-fns";
import SanityImage from "gatsby-plugin-sanity-image";

import PortableText from "./../components/portableText";
import ShareButtons from "./../components/share-buttons";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { toPlainText } from "../lib/helpers";
import MissingAvatar from "./../styles/images/Missing_avatar.inline.svg";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        ...ImageWithPreview
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            ...ImageWithPreview
          }
          _id
          name
          jobTitle
          slug {
            current
          }
        }
      }
      relatedService {
        link
        linkText
        title
        _rawDescription
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...ImageWithPreview
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { location, data, errors } = props;
  const { post, site } = data;
  const keywords = post?.categories?.map((category) => category.title);
  const {
    _rawBody,
    _rawExcerpt,
    authors,
    categories,
    title,
    mainImage,
    publishedAt,
    relatedService,
  } = post;
  const url = `${site.siteMetadata.siteUrl}${location?.pathname || ""}`;
  const description = toPlainText(_rawExcerpt);

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      <SEO
        title={post.title || "Untitled"}
        description={toPlainText(post._rawExcerpt)}
        image={post.mainImage}
        keywords={keywords}
        pathname={post.slug.current}
        location={location}
        type="article"
        article={{
          publishedTime: publishedAt,
          authors: authors.map(
            ({ author }) =>
              `https://cloudalgo.com/authors/${author.slug.current}`,
          ),
          tags: categories.map((category) => category.title),
        }}
      />

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <div className="main">
        <div className="blog-detail-section ">
          <div className="container pt-4 pb-4">
            <h1>{title}</h1>

            <div className="d-flex justify-content-between written-date pt-1">
              <span className="written">
                Written by{" "}
                {authors.map(({ author, _key }) => {
                  const authorName = author && author.name;
                  return (
                    <Link
                      to={`/authors/${author.slug.current}`}
                      key={author._id}
                    >
                      <strong key={_key} className="orange">
                        {authorName || <em>Missing name</em>}
                      </strong>
                    </Link>
                  );
                })}
              </span>
              <span className="blog-date">
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? formatDistance(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM Mo, yyyy")}
              </span>
            </div>
          </div>
          <section className=" blog-banner-section pt-5">
            <div className="container">
              {mainImage && mainImage.asset && (
                <div>
                  <SanityImage
                    {...mainImage}
                    alt={mainImage.alt}
                    width={1000}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          </section>
        </div>

        <section className=" blog-post-details  pb-5">
          <div className="container">
            <div className="row">
              <div className=" col-sm-8  col-lg-9 order-1 main-column">
                {_rawBody && <PortableText blocks={_rawBody} />}

                {relatedService && (
                  <div className="related-services ">
                    <h3>{relatedService.title}</h3>
                    <PortableText blocks={relatedService._rawDescription} />
                    <br />
                    <br />

                    <Link
                      className="btn btn-secondary"
                      aria-label="Salesforce Consulting"
                      to={relatedService.link}
                    >
                      {relatedService.linkText}{" "}
                      <span className="icon-arrow_forward"></span>
                    </Link>
                  </div>
                )}
              </div>
              <div className=" col-sm-4 col-lg-3">
                <aside>
                  <div className="tag-share  mt-2 mb-2">
                    {authors.map(({ author, _key }) => {
                      const authorName = author && author.name;
                      return (
                        <div className="author-profile mb-4" key={author._id}>
                          <div className="author-dp">
                            {!author.image && (
                              <MissingAvatar
                                style={{ width: "100%", height: "100%" }}
                              />
                            )}
                            {author.image && (
                              <SanityImage
                                {...author.image}
                                alt={author.name}
                              />
                            )}
                          </div>
                          <div className="author-details mt-3">
                            <div className="name">
                              <Link to={`/authors/${author.slug.current}`}>
                                {authorName || <em>Missing name</em>}
                              </Link>
                            </div>
                            <div className="designation">
                              {author.jobTitle || <em>Missing job title</em>}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="tags  pb-4">
                      {categories.map((category) => (
                        <Link
                          to={`/category/${category.slug.current}`}
                          key={category._id}
                        >
                          {category.title}
                        </Link>
                      ))}
                    </div>
                    <div className="share-btn pb-4">
                      <ShareButtons
                        url={url}
                        title={title}
                        description={description}
                      />
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;
